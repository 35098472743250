import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSetState } from 'react-use';
import {
  Tabs,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form as FormAntd,
  Radio,
  Checkbox,
  Badge,
  Tooltip,
  Select,
} from 'antd';
import { languages, i18n, isLanguage } from 'lib/utils';
import forms, {
  FormsFieldProps,
  FormsProps,
  IsActiveForm,
  TypeForm,
  FormType,
} from 'lib/api/forms';

import Builder from './Builder';
import { Properties } from 'types';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const formItemStyle = {
  marginBottom: 0,
  lineHeight: 'initial',
};

export interface FormProps {
  onSaved: () => void;
  initialForm?: FormsProps;
  AdditionalModules?: React.FunctionComponent<any>;
}

const defaultData = { code: '', code_ru: '', code_en: '', form_type: FormType.BASIC };

const Form: React.FC<FormProps> = ({ onSaved, initialForm }) => {
  const { id } = useParams<{ id: string }>();
  const [form] = FormAntd.useForm();
  const [loading, setLoading] = React.useState(false);
  const [activeTabKey, setActiveTabKey] = React.useState<string>('ro');

  const [type, setType] = React.useState(TypeForm.REGULAR);
  const [fields, setFields] = React.useState<FormsFieldProps[]>([]);

  const [data, setData] = useSetState<Properties>(defaultData);

  React.useEffect(() => {
    if (initialForm) {
      form.setFieldsValue({
        ...initialForm,
        type: initialForm.type || TypeForm.REGULAR,
      });

      setFields(initialForm.fields);
      setType(initialForm.type || TypeForm.REGULAR);
      setData({ ...initialForm.data });
    }
  }, [initialForm]);

  React.useEffect(() => {
    form.setFieldsValue({
      data: {
        code: data.code,
        code_ru: data.code_ru,
        code_en: data.code_en,
        form_type: data.form_type || FormType.BASIC,
      },
    });
  }, [data]);

  const onChange = (key: string): void => {
    if (isLanguage(key)) setActiveTabKey(key);
  };

  const handleSubmit = async (values: Properties): Promise<void> => {
    const columns = [
      ...new Set(await values?.fields?.map((field) => field.name).filter((field) => field)),
    ];

    setLoading(true);
    try {
      await forms.upsertForm({
        ...values,
        data: { ...data, columns: columns } as Properties,
        send_to: values.send_to || null,
        ...(id && { id }),
      } as FormsProps);
      onSaved();
    } catch (e) {
      const { message = 'Eroare. Formularul nu a putut fi salvat!' } = e as Error;

      Modal.error({ content: message });
    }
    setLoading(false);

    // if (errors !== undefined || errors !== null) {
    //   let errorLang = '';
    //   Object.keys(errors?.i18n as any).forEach((lang) => {
    //     Object.keys(errors?.i18n[lang]).forEach((el) => {
    //       if (errors?.i18n[lang][el]?.errors.length) {
    //         errorLang = lang;
    //       }
    //     });
    //   });
    //   if (errorLang) {
    //     onChange(errorLang);
    //   }
    // }
  };

  const onFinishFailed = ({ errorFields }) => {
    let errorLang = '';

    errorFields.forEach((err) => {
      if (err.name[0] === 'i18n') {
        errorLang = err.name[1];
      }
    });

    if (errorLang) {
      onChange(errorLang);
    }
  };

  const onValuesChange = (values) => {
    if (values.type) setType(values.type);
    if (values.fields) setFields(values.fields);
    if (values?.data) {
      if (Object.keys(values?.data)?.includes('verify'))
        setData((prevState) => ({ ...prevState, verify: values.data.verify }));
      if (Object.keys(values?.data)?.includes('approve_check'))
        setData((prevState) => ({ ...prevState, approve_check: values.data.approve_check }));
      if (Object.keys(values?.data)?.includes('form_type'))
        setData((prevState) => ({ ...prevState, form_type: values.data.form_type }));
    }
  };

  const availableSmsVerify = React.useMemo(
    () => ({
      idnp: !!fields.find(({ name }) => name?.toLowerCase().includes('idnp')),
      phone: !!fields.filter(({ name }) =>
        ['phone', 'telefon'].some((value) => name?.toLowerCase().includes(value)),
      )?.length,
      email: !!fields.filter(({ name }) =>
        ['email', 'e-mail'].some((value) => name?.toLowerCase().includes(value)),
      )?.length,
    }),
    [fields],
  );

  const onChangeCode = (value) => {
    if (activeTabKey === 'ru') {
      setData((prevState) => ({ ...prevState, code_ru: value }));
    } else if (activeTabKey === 'en') {
      setData((prevState) => ({ ...prevState, code_en: value }));
    } else {
      setData((prevState) => ({ ...prevState, code: value }));
    }
  };

  return (
    <FormAntd
      form={form}
      onValuesChange={onValuesChange}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
    >
      <FormAntd.Item name={['data', 'code']} style={{ height: 0, margin: 0 }}>
        <Input type="hidden" />
      </FormAntd.Item>
      <FormAntd.Item name={['data', 'code_ru']} style={{ height: 0, margin: 0 }}>
        <Input type="hidden" />
      </FormAntd.Item>
      <FormAntd.Item name={['data', 'code_en']} style={{ height: 0, margin: 0 }}>
        <Input type="hidden" />
      </FormAntd.Item>
      <Row>
        <Col span={24} style={{ marginBottom: 20 }}>
          <Row gutter={[16, 16]} align="middle" justify="end">
            <Col>
              <Link to="/forms">
                <Button>Anulează</Button>
              </Link>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvează
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginLeft: 10 }}>
          <Tabs
            className="custom-tabs"
            activeKey={activeTabKey}
            onChange={onChange}
            animated={false}
          >
            {languages.map((language) => {
              return (
                <TabPane forceRender tab={i18n.language[language]} key={language}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <FormAntd.Item
                        label="Titlu"
                        name={['i18n', language, 'title']}
                        rules={[
                          {
                            required: true,
                            message: 'Titlul este necesar',
                          },
                        ]}
                        style={formItemStyle}
                      >
                        <Input placeholder={`Introduceți titlul articolului [${language}]`} />
                      </FormAntd.Item>
                    </Col>

                    <Col span={24}>
                      <FormAntd.Item
                        label="Descrierea"
                        name={['i18n', language, 'description']}
                        initialValue={initialForm?.i18n[activeTabKey]?.description}
                        style={formItemStyle}
                      >
                        <Input.TextArea placeholder="Descrierea" />
                      </FormAntd.Item>
                    </Col>
                    <Col span={24}>
                      <FormAntd.Item
                        label="Adresa de e-mail pentru expediere"
                        name="send_to"
                        rules={[
                          {
                            type: 'email',
                            message: 'Adresa pentru expediere nu e valida',
                          },
                        ]}
                        style={formItemStyle}
                      >
                        <Input />
                      </FormAntd.Item>
                    </Col>

                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col>
                          <FormAntd.Item
                            label="Status"
                            name="is_active"
                            initialValue={initialForm?.is_active || false}
                            style={formItemStyle}
                          >
                            <Radio.Group className="flex-no-wrap">
                              <Radio value={true}>{IsActiveForm.ACTIVE}</Radio>
                              <Radio value={false}>{IsActiveForm.INACTIVE}</Radio>
                            </Radio.Group>
                          </FormAntd.Item>
                        </Col>
                        <Col>
                          <FormAntd.Item
                            label="Tip"
                            name="type"
                            initialValue={initialForm?.type || TypeForm.REGULAR}
                            style={formItemStyle}
                          >
                            <Radio.Group className="flex-no-wrap">
                              <Radio value={TypeForm.REGULAR}>Implicit</Radio>
                              <Radio value={TypeForm.STEPS}>Cu Pași</Radio>
                            </Radio.Group>
                          </FormAntd.Item>
                        </Col>
                        {type === TypeForm.STEPS && (
                          <Col>
                            <FormAntd.Item
                              name={['data', 'verify']}
                              initialValue={initialForm?.data?.verify || false}
                              valuePropName="checked"
                              style={formItemStyle}
                            >
                              <Checkbox>
                                <Badge
                                  count={
                                    <Tooltip
                                      title={
                                        <Row wrap style={{ width: 150 }}>
                                          <Col xs={24}>Câmpuri obligatorii</Col>
                                          <Col xs={24}>
                                            <Row justify="space-between">
                                              IDNP
                                              {availableSmsVerify.idnp ? (
                                                <CheckCircleOutlined style={{ color: 'lime' }} />
                                              ) : (
                                                <CloseCircleOutlined style={{ color: 'red' }} />
                                              )}
                                            </Row>
                                          </Col>
                                          <Col xs={24}>
                                            <Row justify="space-between">
                                              Phone
                                              {availableSmsVerify.phone ? (
                                                <CheckCircleOutlined style={{ color: 'lime' }} />
                                              ) : (
                                                <CloseCircleOutlined style={{ color: 'red' }} />
                                              )}
                                            </Row>
                                          </Col>
                                          <Col xs={24}>
                                            <Row justify="space-between">
                                              Email
                                              {availableSmsVerify.email ? (
                                                <CheckCircleOutlined style={{ color: 'lime' }} />
                                              ) : (
                                                <CloseCircleOutlined style={{ color: 'red' }} />
                                              )}
                                            </Row>
                                          </Col>
                                        </Row>
                                      }
                                    >
                                      <InfoCircleOutlined
                                        style={{
                                          color: Object.keys(availableSmsVerify).some(
                                            (value) => !availableSmsVerify[value],
                                          )
                                            ? 'red'
                                            : 'green',
                                        }}
                                      />
                                    </Tooltip>
                                  }
                                >
                                  <span style={{ marginRight: 10 }}>SMS Validarea</span>
                                </Badge>
                              </Checkbox>
                            </FormAntd.Item>
                          </Col>
                        )}

                        <Col>
                          <FormAntd.Item
                            name={['data', 'approve_check']}
                            initialValue={initialForm?.data?.approve_check || true}
                            valuePropName="checked"
                            style={formItemStyle}
                          >
                            <Checkbox>
                              <span style={{ marginRight: 10 }}>
                                Aproba verificarea pentru solicitarea informațiilor.
                              </span>
                            </Checkbox>
                          </FormAntd.Item>
                        </Col>

                        <Col>
                          <FormAntd.Item
                            name="confirm_phone"
                            initialValue={initialForm?.confirm_phone}
                            valuePropName="checked"
                            style={formItemStyle}
                          >
                            <Checkbox>
                              <span style={{ marginRight: 10 }}>
                                Validarea numarului de telefon
                              </span>
                            </Checkbox>
                          </FormAntd.Item>
                        </Col>

                        <Col>
                          <FormAntd.Item
                            name="confirm_idnp"
                            initialValue={initialForm?.confirm_idnp}
                            valuePropName="checked"
                            style={formItemStyle}
                          >
                            <Checkbox>
                              <span style={{ marginRight: 10 }}>Validarea IDNP</span>
                            </Checkbox>
                          </FormAntd.Item>
                        </Col>

                        <Col>
                          <FormAntd.Item
                            label="Tipul de formă"
                            name={['data', 'form_type']}
                            style={formItemStyle}
                            rules={[
                              {
                                required: true,
                                message: 'Tipul de formă este necesar!',
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              placeholder="Tipul de formă"
                              style={{ width: '140px' }}
                            >
                              <Select.Option value={FormType.BASIC}>De bază</Select.Option>
                              <Select.Option value={FormType.INDIVIDUALS}>
                                Persoane fizice
                              </Select.Option>
                              <Select.Option value={FormType.BUSINESS}>
                                Persoane juridice
                              </Select.Option>
                            </Select>
                          </FormAntd.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <FormAntd.Item
                        label=""
                        name="fields"
                        initialValue={initialForm?.fields || []}
                        rules={[
                          {
                            required: true,
                            message: 'Adaugarea componentului este necesar',
                          },
                        ]}
                        style={{ ...formItemStyle, paddingBottom: 16 }}
                      >
                        <Builder
                          activeTabKey={activeTabKey}
                          type={type}
                          code={{
                            ro: data.code,
                            ru: data.code_ru,
                            en: data.code_en,
                          }}
                          onChangeCode={onChangeCode}
                        />
                      </FormAntd.Item>
                    </Col>
                  </Row>
                </TabPane>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </FormAntd>
  );
};

export default Form;
